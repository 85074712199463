import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import '../../styles/ant-style.less';

function SEO({ lang, meta, title }) {
      return (
            <Helmet
                  htmlAttributes={{
                        lang,
                  }}
                  title={title}
                  titleTemplate={title}
                  meta={[
                        {
                              property: `og:title`,
                              content: title,
                        },
                        {
                              property: `og:type`,
                              content: `website`,
                        },
                        {
                              name: `twitter:card`,
                              content: `summary_large_image`,
                        },
                        {
                              name: `twitter:title`,
                              content: title,
                        },
                  ].concat(meta)}
            ></Helmet>
      );
}

SEO.defaultProps = {
      lang: `en`,
      meta: [],
};

SEO.propTypes = {
      lang: PropTypes.string,
      meta: PropTypes.arrayOf(PropTypes.object),
      title: PropTypes.string.isRequired,
};

export default SEO;
